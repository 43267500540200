import React from 'react';

import './Settings.css';

export function Control({ children }) {
  return <div className="control">{children}</div>;
}

export default function Settings({ settings, onClose, onSettingChange }) {
  return (
    <div className="settings">
      <Control>
        <label>Layout:</label>
        <select
          name="layout"
          value={settings.layout}
          onChange={(event) => onSettingChange('layout', event.target.value)}
        >
          <option value="list">List</option>
          <option value="grid">Grid</option>
        </select>
        <p className="explanation">Control how graphs are arranged.</p>
      </Control>

      <button type="button" onClick={onClose}>
        Close
      </button>
    </div>
  );
}
