export const logger = ({ name, color }) => (...args) =>
  console.log(
    `%c[${name}]%c`,
    `color: ${color}; font-weight: bold`,
    'color: inherit; font-weight: inherit',
    ...args
  );

export const log = logger({ name: 'elstat', color: 'purple' });

export function objectFromEntries(entries) {
  return entries.reduce(
    (object, [key, value]) => ({ ...object, [key]: value }),
    {}
  );
}

export function truncateToTwoPlaces(number) {
  // https://stackoverflow.com/a/4187164/2491753
  return number.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0];
}

export async function strictFetch(...args) {
  const resp = await fetch(...args);

  if (!resp.ok) {
    throw new Error(`Failed to fetch: ${resp.status} ${resp.statusText}`);
  }

  return resp;
}

export function request(url, ...args) {
  return strictFetch(process.env.REACT_APP_SHENLONG_API_URL + url, ...args);
}
