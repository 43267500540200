import React, { Component } from 'react';
import classnames from 'classnames';

import './Dashboard.css';
import Service from './Service.js';
import DegradedNotice from './DegradedNotice';
import StreamingClient from '../ws/client';
import Loading from './Loading';
import Notice from './Notice';
import Navbar from './Navbar';
import Status from './Status';
import Container from './Container';
import Settings from './Settings';
import { log, objectFromEntries, request } from '../util';

const SETTINGS_KEY = '_elstatSettings';
const DEFAULT_SETTINGS = { layout: 'list' };

export default class Dashboard extends Component {
  client = null;

  state = {
    loading: true,
    error: null,
    metrics: null,
    incident: null,
    showingSettings: false,
    settings: DEFAULT_SETTINGS,
  };

  async componentDidMount() {
    const settingsJson = window.localStorage.getItem(SETTINGS_KEY);

    if (settingsJson != null) {
      try {
        this.setState({ settings: JSON.parse(settingsJson) });
      } catch (err) {
        console.error('[dashboard] failed to load settings', err);
        window.localStorage.setItem(
          SETTINGS_KEY,
          JSON.stringify(DEFAULT_SETTINGS)
        );
      }
    }

    try {
      await this.loadMetrics();
      await this.loadIncident();
    } catch (error) {
      this.setState({ error: error.toString() });
    }

    this.setState({ loading: false });
    if (this.state.error) {
      return;
    }

    const endpoint = `${process.env.REACT_APP_SHENLONG_API_URL}/api/streaming`
      .replace('https', 'wss')
      .replace('http', 'ws');

    this.client = new StreamingClient(endpoint, this.state.metrics);
    this.client.connect();

    this.client.on('status', this.handleStatus.bind(this));
    this.client.on('latency', this.handleLatency.bind(this));

    this.client.on('incident_new', (incident) => {
      log('received new incident from websocket:', incident);
      this.setState({ incident });
    });
    this.client.on('incident_update', (incident) => {
      log('received incident update from websocket:', incident);
      this.setState({ incident });
    });
    this.client.on('incident_close', () => {
      log('received incident close from websocket');
      this.setState({ incident: null });
    });
  }

  componentWillUnmount() {
    if (this.client != null) {
      this.client.disconnect();
    }
  }

  get allServices() {
    return Object.entries(this.state.metrics.status);
  }

  handleStatus(name, [, status]) {
    const { status: statuses } = this.state.metrics;
    log('updating status on:', name);

    if (!(name in statuses)) {
      log(`failed to locate channel ${name} to update statuses`);
      return;
    }

    if (statuses[name].status === status) {
      log(`ignoring stale status (${status}) for ${name}`);
      return;
    }

    this.setState(({ metrics: old }, _props) => {
      const metrics = { ...old };
      metrics.status[name].status = status;

      return { metrics };
    });
  }

  handleLatency(name, data) {
    const { metrics } = this.state;

    log('adding latency entry:', data);

    // latency entries come in newest to oldest, so remove the oldest entry
    const graph = metrics.graph[name].slice(0, metrics.graph[name].length - 1);

    // make new data come in first
    const newGraph = [data, ...graph];

    this.setState(({ metrics: old }, _props) => {
      const metrics = { ...old };
      metrics.graph[name] = newGraph;

      const [, latency] = data;
      metrics.status[name].latency = latency;

      return { metrics };
    });
  }

  async loadMetrics() {
    log('loading metrics');

    const resp = await request('/api/status');
    this.setState({ metrics: await resp.json() });
  }

  async loadIncident() {
    log('loading current incident');

    const resp = await request(`/api/incidents/current`);
    this.setState({ incident: await resp.json() });
  }

  renderNotice(services) {
    const down = services.filter(([, { status }]) => !status);

    // DegradedNotice should only be shown when there is no ongoing incident,
    // and any services are reported as down.
    if (this.state.incident == null && down.length > 0) {
      log(
        'no incident active and some services are down, showing ad-hoc degraded notice'
      );
      return <DegradedNotice services={objectFromEntries(down)} />;
    }

    return <Status incident={this.state.incident} />;
  }

  renderServices(services) {
    const { graph: graphs, uptime: uptimes } = this.state.metrics;
    return services.map(([name, info]) => (
      <Service
        name={name}
        key={name}
        graph={graphs[name]}
        uptime={parseFloat(uptimes[name])}
        {...info}
      />
    ));
  }

  handleSettingChange = (name, value) => {
    this.setState(
      (prevState) => ({
        settings: { ...prevState.settings, [name]: value },
      }),
      this.saveSettings
    );
  };

  saveSettings() {
    window.localStorage.setItem(
      SETTINGS_KEY,
      JSON.stringify(this.state.settings)
    );
  }

  render() {
    const footerHtml = process.env.REACT_APP_SHENLONG_DASHBOARD_FOOTER;
    const footer = footerHtml ? (
      <footer
        style={{
          padding: '0.5rem 2rem',
          backgroundColor: 'var(--bg-secondary)',
        }}
        dangerouslySetInnerHTML={{ __html: footerHtml }}
      />
    ) : null;

    let content;

    if (this.state.error) {
      content = (
        <Container>
          <Notice color="red">{this.state.error}</Notice>
        </Container>
      );
    } else if (this.state.loading) {
      content = (
        <div style={{ marginTop: '2rem' }}>
          <Loading />
        </div>
      );
    } else {
      content = (
        <>
          {this.renderNotice(this.allServices)}
          <Container>{this.renderServices(this.allServices)}</Container>
          {footer}
        </>
      );
    }

    return (
      <main
        className={classnames('dashboard', {
          'grid-view': this.state.settings.layout === 'grid',
        })}
      >
        <Navbar
          right={
            <>
              <span className="subtitle">
                {process.env.REACT_APP_SHENLONG_DASHBOARD_SUBTITLE}
              </span>
              <button
                type="button"
                onClick={() =>
                  this.setState((state) => ({
                    showingSettings: !state.showingSettings,
                  }))
                }
              >
                Settings
              </button>
            </>
          }
        >
          <h1>{process.env.REACT_APP_SHENLONG_TITLE}</h1>
        </Navbar>
        {content}
        {this.state.showingSettings ? (
          <Settings
            settings={this.state.settings}
            onSettingChange={this.handleSettingChange}
            onClose={() => this.setState({ showingSettings: false })}
          />
        ) : null}
      </main>
    );
  }
}
