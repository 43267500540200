import React from 'react';

import { AuthContext } from './Auth';
import Notice from './Notice';
import Container from './Container';

export default function Login() {
  const [error, setError] = React.useState(null);
  const authState = React.useContext(AuthContext);
  const [token, setToken] = React.useState('');

  function handleTokenInputChange(event) {
    setToken(event.target.value);
  }

  function handleSubmit(event) {
    event.preventDefault();
    authState.actions.authenticate(token).catch((error) => {
      setError(error);
    });
  }

  return (
    <Container>
      <h2>Login</h2>
      <p>
        You need to login to access this page. Please enter the password that
        you specified in the config file.
      </p>
      {error && (
        <Notice color="red">
          <strong>Failed to login:</strong> {error.toString()}
        </Notice>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Token</label>
          <input
            type="password"
            value={token}
            style={{ fontFamily: 'monospace' }}
            placeholder={'\u{2022}'.repeat(32)}
            onChange={handleTokenInputChange}
          />
        </div>
        <Notice color="neutral">
          This value will be hashed and saved in your browser's{' '}
          <code>localStorage</code> in order to interact with{' '}
          <a href="https://gitlab.com/elixire/elstat/-/blob/master/docs/incidents.md">
            the API
          </a>
          .
        </Notice>
        <div className="form-group">
          <input type="submit" value="Login" />
        </div>
      </form>
    </Container>
  );
}
