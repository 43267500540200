import React from 'react';

import Container from './Container';

export default function NewIncident() {
  return (
    <Container>
      <p>This is where you'll be able to create a new incident, hopefully.</p>
    </Container>
  );
}
