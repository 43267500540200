import React from 'react';

import { AuthContext } from './Auth';
import Login from './Login';

export default function AuthRoute(props) {
  const authState = React.useContext(AuthContext);
  let { as: Component, ...compProps } = props;
  return authState.authenticated ? <Component {...compProps} /> : <Login />;
}
