const OPS = {
  UNSUBSCRIBE: -1,
  SUBSCRIBE: 0,
  SUBSCRIBED: 1,
  UNSUBSCRIBED: 2,
  DATA: 3,
  INCIDENT_NEW: 4,
  INCIDENT_UPDATE: 5,
  INCIDENT_CLOSE: 6,
  SUBSCRIBE_ALL: 7,
};

export default OPS;
