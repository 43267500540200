import React from 'react';
import PropTypes from 'prop-types';

import './Incident.css';
import Stage from './Stage';
import ms from 'ms';

const OUTAGE_TYPES = {
  major_outage: 'Major Outage',
  outage: 'Outage',
  partial_outage: 'Partial Outage',
  degraded_service: 'Degraded Service',
};

export default function Incident({ incident }) {
  const {
    content,
    start_timestamp: start,
    end_timestamp: end,
    title,
    incident_type: type,
    stages,
  } = incident;

  const startDate = new Date(start);
  const endDate = end ? new Date(end) : null;

  const tooltip =
    `Started ${startDate.toLocaleString()}` +
    (endDate ? `, ended ${endDate.toLocaleString()}` : '');
  const ago = ms(Date.now() - start);
  const agoEnd = end ? ms(Date.now() - end) : null;

  const stageNodes = stages.map((stage) => (
    <Stage key={stage.title} stage={stage} />
  ));

  return (
    <div className="incident">
      <h2>
        {OUTAGE_TYPES[type] || type}: {title}
      </h2>
      <p>{content}</p>
      <footer title={tooltip}>
        Started {ago} ago ({startDate.toISOString()} UTC)
        {end ? `, ended ${agoEnd} ago (${endDate.toISOString()})` : null}
      </footer>
      {stageNodes.length ? <div className="stages">{stageNodes}</div> : null}
    </div>
  );
}

Incident.propTypes = {
  incident: PropTypes.shape({
    content: PropTypes.string,
    end_date: PropTypes.number,
    start_date: PropTypes.number,
    title: PropTypes.string,
    type: PropTypes.string,
    stages: PropTypes.array,
  }),
};
