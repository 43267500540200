import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Notice.css';

export default function Notice({ children, color, className }) {
  return (
    <div className={classnames('notice', `notice-${color}`, className)}>
      {children}
    </div>
  );
}

Notice.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired,
};
