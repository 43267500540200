import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Service.css';
import Graph from './Graph.js';
import { truncateToTwoPlaces } from '../util';

const icons = {
  alive: 'check-circle',
  slow: 'exclamation-circle',
  dead: 'times-circle',
};

const titles = {
  alive: 'This service is healthy.',
  slow: 'This service is slow, but responding.',
  dead: 'This service is unresponsive.',
};

export function getServiceState(status, latency, threshold) {
  if (status && latency > threshold) {
    return 'slow';
  }

  return status ? 'alive' : 'dead';
}

export default function Service({
  graph,
  name,
  status,
  latency,
  description,
  uptime,
  latency_threshold: latencyThreshold,
}) {
  const state = getServiceState(status, latency, latencyThreshold);

  const title = titles[state];
  const icon = icons[state];

  const className = classnames('service', `service-${state}`);
  const uptimePercentage = truncateToTwoPlaces(uptime);

  return (
    <div className={className}>
      <header>
        <div className="emoji" title={title}>
          <FontAwesomeIcon title={title} icon={icon} />
        </div>
        <h2 className="title">
          {name}{' '}
          {latency ? (
            <span className="information">
              {Math.round(latency)}
              ms ({uptimePercentage}
              %)
            </span>
          ) : null}
        </h2>
      </header>
      <p className="description">{description}</p>
      {graph ? <Graph data={graph} /> : null}
    </div>
  );
}

Service.defaultProps = {
  graph: null,
  latency: null,
};

Service.propTypes = {
  graph: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  name: PropTypes.string.isRequired,
  status: PropTypes.bool.isRequired,
  latency: PropTypes.number,
  description: PropTypes.string.isRequired,
};
