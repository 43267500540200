import React from 'react';
import PropTypes from 'prop-types';

import './DegradedNotice.css';
import Notice from './Notice';

export default function DegradedNotice({ services }) {
  const keys = Object.keys(services);
  const serviceNames = keys.join(', ');

  const plural = keys.length === 1 ? '' : 's';
  const indicative = keys.length === 1 ? 'is' : 'are';

  return (
    <Notice color="red" className="degraded-notice">
      <header>
        {keys.length} service{plural} {indicative} unreachable
      </header>
      <p>
        {process.env.REACT_APP_SHENLONG_TITLE} is having trouble contacting{' '}
        <strong>{serviceNames}</strong>.
      </p>
    </Notice>
  );
}

DegradedNotice.propTypes = {
  services: PropTypes.object.isRequired,
};
