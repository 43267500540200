import React from 'react';

import Container from './Container';

export default function NotFound() {
  return (
    <Container>
      <h1>404</h1>
      <p>Page not found! Sorry.</p>
    </Container>
  );
}
