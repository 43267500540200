import React from 'react';

import { Router } from '@reach/router';

import Auth from './Auth';
import Dashboard from './Dashboard';
import Incidents from './Incidents';
import NotFound from './NotFound';
import AuthRoute from './AuthRoute';
import NewIncident from './NewIncident';

export default function App() {
  return (
    <Auth>
      <Router>
        <Dashboard path="/" />
        <Incidents path="/incidents/" />
        <AuthRoute path="/incidents/new" as={NewIncident} />
        <NotFound default />
      </Router>
    </Auth>
  );
}
