import React from 'react';
import PropTypes from 'prop-types';

import './Navbar.css';

export default function Navbar(props) {
  return (
    <nav className="navbar">
      {props.children} <div className="right">{props.right}</div>
    </nav>
  );
}

Navbar.propTypes = {
  children: PropTypes.any,
};
