import React from 'react';
import PropTypes from 'prop-types';

import ms from 'ms';
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ReferenceLine,
  Label,
} from 'recharts';

import './Graph.css';

function useMediaQuery(query) {
  const mql = window.matchMedia(query);
  const [matches, setMatches] = React.useState(mql.matches);

  React.useEffect(() => {
    const listener = (event) => setMatches(event.matches);
    mql.addListener(listener);
    return () => mql.removeListener(listener);
  }, [mql]);

  return matches;
}

export default function Graph({ data }) {
  const isSmallScreen = useMediaQuery('(max-width: 500px)');
  const isDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  const processedData = data
    .map(([timestamp, latency]) => ({
      timestamp,
      latency,
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  const tick = { fill: 'currentColor', opacity: 0.75 };

  const areaColorscheme = isDarkMode
    ? {
        fill: 'hsl(200, 60%, 20%)',
        stroke: 'hsl(200, 60%, 60%)',
      }
    : {
        fill: 'hsl(200, 100%, 85%)',
        stroke: 'hsl(200, 100%, 55%)',
      };

  const yAxis = isSmallScreen ? null : (
    <YAxis
      dataKey="latency"
      tickLine={false}
      tickFormatter={(tick) => (tick === 0 ? '' : ms(tick))}
      tick={tick}
      axisLine={false}
      type="number"
    />
  );

  function formatXTick(timestamp) {
    const delta = Date.now() - timestamp;
    if (delta < 100) {
      return 'now';
    }
    return ms(delta);
  }

  return (
    <div className="graph-container">
      <ResponsiveContainer width="100%" height={175}>
        <AreaChart data={processedData}>
          <XAxis
            dataKey="timestamp"
            tickFormatter={formatXTick}
            tickLine={false}
            axisLine={false}
            tick={tick}
            type="number"
            domain={['dataMin', 'dataMax']}
          />
          {yAxis}
          <CartesianGrid
            strokeDasharray="3 3"
            stroke="currentColor"
            opacity="0.25"
          />
          <Tooltip
            isAnimationActive={false}
            formatter={(value) => `${value}ms`}
            label="DAB"
            separator=": "
            labelFormatter={(time) => ms(Date.now() - time) + ' ago'}
          />
          <ReferenceLine
            y={1000}
            label={<Label fill="currentColor">1s</Label>}
            stroke="red"
          />
          <Area
            type="monotone"
            dataKey="latency"
            isAnimationActive={false}
            {...areaColorscheme}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

Graph.propTypes = {
  data: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)).isRequired,
};
