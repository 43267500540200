import React from 'react';

import bouncingBlob from '../assets/ablobbounce.png';

export default function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <img src={bouncingBlob} alt=":ablobbounce:" />
    </div>
  );
}
