import React from 'react';
import PropTypes from 'prop-types';

import './Status.css';
import Incident from './Incident';
import Notice from './Notice';

export default function Status({ incident }) {
  let node;
  const incidentOngoing = incident != null && incident.ongoing;

  if (incidentOngoing) {
    node = <Incident incident={incident} />;
  } else {
    node = 'All systems operational';
  }

  return <Notice color={incidentOngoing ? 'red' : 'green'}>{node}</Notice>;
}

Status.propTypes = {
  incident: PropTypes.object,
};
