import React from 'react';
import PropTypes from 'prop-types';

import ms from 'ms';

export default function Stage({ stage }) {
  const { timestamp, title, content } = stage;
  const date = new Date(timestamp);
  const ago = ms(Date.now() - timestamp);

  return (
    <div className="stage">
      <h3>{title}</h3>
      <p>{content}</p>
      <footer>
        {ago} ago ({date.toISOString()} UTC)
      </footer>
    </div>
  );
}

Stage.propTypes = {
  stage: PropTypes.shape({
    created_at: PropTypes.number,
    title: PropTypes.string,
    content: PropTypes.string,
  }),
};
