import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faTimesCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

export default function register() {
  library.add(faCheckCircle, faTimesCircle, faExclamationCircle);
}
