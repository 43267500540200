import React from 'react';

import './Incidents.css';
import Notice from './Notice';
import Container from './Container';
import Incident from './Incident';
import { request } from '../util';

export default class Incidents extends React.Component {
  state = {
    incidents: null,
    error: null,
    page: 0,
  };

  componentDidMount() {
    this.fetchIncidents();
  }

  async fetchIncidents() {
    console.log('Fetching incidents.');
    try {
      const resp = await request(`/api/incidents/${this.state.page}`);
      this.setState({ incidents: await resp.json() });
    } catch (error) {
      this.setState({ error });
    }
  }

  renderIncidents() {
    if (!this.state.incidents) {
      return null;
    }

    if (this.state.incidents.length === 0) {
      return 'No incidents here!';
    }

    return this.state.incidents.map((incident) => (
      <Incident key={incident.id} incident={incident} />
    ));
  }

  render() {
    let body;

    if (this.state.error != null) {
      body = (
        <Notice color="red">
          <strong>Failed to load incidents:</strong>{' '}
          {this.state.error.toString()}
        </Notice>
      );
    } else if (this.state.incidents != null) {
      body = this.renderIncidents();
    } else {
      body = 'Loading incidents for ya...';
    }

    return (
      <Container>
        <h1>Incidents</h1>
        <div className="incident-list">{body}</div>
      </Container>
    );
  }
}
