import React from 'react';

const DEFAULT_STATE = { authenticated: false };
export const TOKEN_KEY = '_elstatManagementToken';

export const AuthContext = React.createContext(DEFAULT_STATE);
AuthContext.displayName = 'AuthContext';

function hexifyByte(byte) {
  // Make sure 0xf becomes "0f", not "f".
  return ('00' + byte.toString(16)).slice(-2);
}

async function sha256(token) {
  // https://stackoverflow.com/a/48161723
  const encoded = new TextEncoder().encode(token);
  const digestBuffer = await window.crypto.subtle.digest('SHA-256', encoded);
  const digestBytes = Array.from(new Uint8Array(digestBuffer));
  const hex = digestBytes.map(hexifyByte).join('');
  return hex;
}

function synthesizeActions(setValue) {
  return {
    async authenticate(token) {
      const hashedToken = await sha256(token);
      window.localStorage.setItem(TOKEN_KEY, hashedToken);
      setValue({ authenticated: true, token: hashedToken });
    },
    deauthenticate() {
      window.localStorage.removeItem(TOKEN_KEY);
      setValue(DEFAULT_STATE);
    },
  };
}

function computeAuthState() {
  const value = window.localStorage.getItem(TOKEN_KEY);

  if (value == null) {
    return DEFAULT_STATE;
  } else {
    return { authenticated: true, token: value };
  }
}

export default function Auth(props) {
  const [value, setValue] = React.useState(computeAuthState());
  const amendedValue = { ...value, actions: synthesizeActions(setValue) };

  return (
    <AuthContext.Provider value={amendedValue}>
      {props.children}
    </AuthContext.Provider>
  );
}
